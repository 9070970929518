body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: rgba(26, 26, 26, 0.383);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 7px;
	opacity: 0.8;
}

*::-webkit-scrollbar-track {
	background: none;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(26, 26, 26, 0.383);
	border-radius: 20px;
	opacity: 0;
}
*::-webkit-scrollbar-thumb:hover {
	background: rgba(26, 26, 26, 0.383);
	border-radius: 5px;
	opacity: 0.5;
}
*::-webkit-scrollbar-corner {
	background: rgba(0, 0, 0, 0);
}

#root {
	flex: 1 1 auto;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
