.recipeHeading {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-weight: 500;
	margin-bottom: 5px !important;
	max-width: 70%;
	line-height: 24px;
	font-size: 18px;
}
