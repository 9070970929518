.fu_fileUpload {
    /* background-color: red; */
}

input#file-upload-button {
    display: hidden;
    background-color: green;
}

.fu_fileUpload::-webkit-file-upload-button {
    display: none;
 }