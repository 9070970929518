.dashboardTemplate a {
    color: #4299E1;
    text-decoration: underline;
}

.dashboardTemplate h1 {
    font-size: 24px;
    margin: 12px 0px 8px 0px;
}

.dashboardTemplate h2 {
    font-size: 20px;
    margin: 8px 0px 5px 0px;
}

/* 
    EDITOR STYLES
*/

.ql-toolbar {
    border: 1px solid #E2E8F0 !important;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    /* background-color: #F7F6F3; */
    background-color: #EDF2F7;
}

.ql-container {
    border: 1px solid #E2E8F0 !important;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    flex: 1 1 auto;
}

.ql-editor {
    border: none !important;
    width: 100% !important;
    height: 100% !important;
}

.quill {
    display: flex !important;
    flex-direction: column;
    height: 100%;
    min-height: 250px;
}