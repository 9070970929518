.node-status-link {
    /* background-color: red; */
}

.node-status-container {
    color: #7D7D7D !important;
    margin-bottom: 0px !important;
}

.node-status-container p {
    color: #7D7D7D !important;
    margin-bottom: 0px !important;
}

.node-status-container a {
    color: #7D7D7D !important;
}

.node-status-container a:hover {
    text-decoration: underline !important;
}