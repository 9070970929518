.dndflow {
	/* flex-direction: column;
	display: flex;
	height: 100%;
	background: #313131; */
}

.dndflow aside {
	border-right: 1px solid #eee;
	padding: 15px 10px;
	font-size: 12px;
	background: #fcfcfc;
}

.dndflow aside > * {
	margin-bottom: 10px;
	cursor: grab;
}

.dndflow aside .description {
	margin-bottom: 10px;
}

.dndflow .reactflow-wrapper {
	flex-grow: 1;
	height: 100%;
}

.react-flow__connection-path {
	stroke-width: 1.5;
	stroke: #a9a9a9;
}

/* Styling the edges */

.react-flow__edge-path {
	stroke-width: 2.5;
	stroke: #acacac;
}
.react-flow__edge.selected .react-flow__edge-path {
	stroke: hsl(32, 100%, 49%) !important;
	transition: all 0.1s cubic-bezier(0.08, 0.52, 0.52, 1);
}

/* Styles for the dummy edge */
.react-flow__edge-path-selector {
	fill: none;
	stroke: transparent;
	stroke-width: 20;
}

/* Change the stroke of the edge path when the cursor is hovered over either the dummy or the actual edge */
.react-flow__edge-path:hover {
	stroke: hsl(32, 100%, 65%);
}
.react-flow__edge-path-selector:hover + .react-flow__edge-path {
	stroke: hsl(32, 100%, 65%);
}

@media screen and (min-width: 768px) {
	.dndflow {
		flex-direction: row;
	}

	.dndflow aside {
		width: 20%;
		max-width: 180px;
	}
}
